<template>
  <div class="category-list">
    <error :errors="categoryError" />
    <b-overlay :show="formBusy" rounded="sm">
      <portlet fluidHeight>
        <template v-slot:body>
          <div class="d-flex align-items-center justify-content-between">
            <b-pagination
              class="mb-0 mr-2"
              v-model="categoriesMeta.current_page"
              :total-rows="categoriesMeta.total"
              :per-page="categoriesMeta.per_page"
              aria-controls="categories"
              @change="onChangePage"
            />
            <div class="kt-searchbar d-none d-sm-flex w-25">
              <div class="kt-input-icon kt-input-icon--left">
                <b-input @blur="refreshList" v-model="title" type="search" class="form-control" placeholder="تۈر نامىنى كىرگۈزۈپ ئىزدەڭ" />
                <span class="kt-input-icon__icon kt-input-icon__icon--left">
                    <span><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <rect x="0" y="0" width="24" height="24"></rect>
                          <path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                          <path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z" fill="#000000" fill-rule="nonzero"></path>
                        </g>
                      </svg></span>
                  </span>
              </div>
            </div>
            <div class="actions">
              <button @click="refreshList" v-b-tooltip.hover  title="يېڭىلاش" class="btn btn-clean btn-sm btn-icon btn-icon-md">
                <i class="flaticon2-refresh-arrow"></i>
              </button>
              <b-button :to="{name: 'categories.create'}" class="btn btn-label-success btn-sm border-0">
                يېڭىدىن قوشۇش
              </b-button>
            </div>
          </div>
        </template>
      </portlet>
      <b-row>
        <b-col v-for="category in categories" md="3" :key="category.id" sm="6">
          <portlet title=" " fluidHeight head-class="kt-portlet__head--noborder">
            <template v-slot:toolbar>
              <b-form-checkbox v-b-tooltip title="ھالەت تەڭشىكى" :key="`${category.id}_show`" switch @change="onSwitchShow(category.id)" v-model="category.is_show"/>
              <b-form-checkbox v-b-tooltip title="باشبەتتە كۆرۈنەمدۇ" :key="`${category.id}_home`" switch @change="onSwitchInHome(category.id)" v-model="category.in_home"/>
              <b-dropdown v-b-tooltip title="مەشغۇلات" toggle-class="text-decoration-none border-0 btn btn-clean btn-sm btn-icon btn-icon-md" no-caret>
                <template v-slot:button-content>
                  <i class="fa fa-ellipsis-v"></i>
                </template>
                <b-dropdown-item @click="deleteItem(category.id)">
                  <i class="fa fa-trash"></i> ئۆچۈرۈش
                </b-dropdown-item>
              </b-dropdown>
            </template>
            <template v-slot:body>
              <div class="kt-widget kt-widget--user-profile-2">
                <div class="kt-widget__head">
                  <div class="kt-widget__media">
                    <b-img class="kt-widget__img" :class="{'kt-hidden': ! category.icon_image_preview}" rounded="" height="50" :src="category.icon_image_preview" alt="image" />
                    <div :class="{'kt-hidden': category.icon_image_preview}" class="kt-widget__pic kt-widget__pic--success kt-font-success kt-font-boldest">
                      {{ category.title }}
                    </div>
                  </div>
                  <div class="kt-widget__info">
                    <a class="kt-widget__username">
                      {{ category.title }}
                    </a>
                  </div>
                </div>
                <div class="kt-widget__body">
                  <div class="kt-widget__section">
                    <p class="rounded" :style="{backgroundColor: category.background, height: '20px', marginBottom: 0}"></p>
                  </div>
                  <div class="kt-widget__item">
                    <div class="kt-widget__contact">
                      <span class="kt-widget__label">قوشۇلغان ئەسەر: </span>
                      <span class="kt-widget__data" >{{ category.articles_count}} پارچە ئەسەر قوشۇلغان</span>
                    </div>
                  </div>
                </div>
                <div class="kt-widget__footer">
                  <b-button :to="{name: 'categories.edit', params: {id: category.id}}" type="button" variant="primary" class="btn btn-lg btn-upper">تەھرىرلەش</b-button>
                </div>
              </div>
            </template>
          </portlet>
        </b-col>
      </b-row>
      <portlet v-if="categories.length < 1" class="kt-callout">
        <template v-slot:body>
          <div class="kt-callout__body">
            <div class="kt-callout__content">
              <h3 class="kt-callout__title">ئۇچۇر تېپىلمىدى</h3>
              <p class="kt-callout__desc">
                مۇناسىۋەتلىك ئ‍ۇچۇر تېپىلمىدى، يېڭىدىن ئۇچۇر قوشۇڭ
              </p>
            </div>
            <div class="kt-callout__action">
              <b-button :to="{name: 'categories.create'}" class="btn-custom btn-bold btn-upper btn-font-sm  btn-success">يېڭىدىن قوشۇش</b-button>
            </div>
          </div>
        </template>
      </portlet>
    </b-overlay>
  </div>
</template>

<script>
  import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
  import Error from "../../../partials/widgets/Error";
  import Portlet from "../../../partials/content/Portlet";
  import { mapGetters } from "vuex";
  import categoryMixin from "../../../../mixins/categoryMixin";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  export default {
    name: "index",
    mixins: [ categoryMixin, formBusyMixin ],
    components: { Error, Portlet },
    computed: {
      ...mapGetters(["categoryError", "categories", "categoriesMeta"])
    },
    created() {
      this.getCategories()
    },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "تۈر باشقۇرۇش", route: "list" },
        { title: "بارلىق تۈرلەر" }
      ]);
    },
    data(){
      return {
        tableFields: [
          { key: "id", label: "id", class: ['d-none', 'd-md-table-cell'] },
          { key: "title", label: "ماۋزو" },
          { key: "switchShow", label: "ھالىتى" },
          { key: "actions", label: "مەشغۇلات" }
        ],
        page: 1,
        title: '',
        formBusy: false,
      };
    },
    methods: {
      onChangePage(page){
        this.page = page;
        let title = this.title;
        this.getCategories({page, title});
      },
      refreshList(){
        let title = this.title;
        this.getCategories({page: 1, title});
      },
      deleteItem(id) {
        this.$bvModal.msgBoxConfirm('مەزكۇر تۈرنى ئ‍ۆچۈرسىڭىز ئەسلىگە قايتۇرغىلى بولمايدۇ، ئۆچۈرۈشنى جەزىملەشتۈرەمسىز ؟', {
          title: 'راستىنلا ئۆچۈرەمسىز ؟',
          okVariant: 'danger',
          okTitle: 'جەزىملەشتۈرىمەن',
          cancelTitle: 'ياق',
          footerClass: 'p-2',
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if ( ! value ) return;
          this.deleteCategory(id)
            .then(response=>{
              let page = this.page;
              let title = this.title;
              this.getCategories({page, title});
            })
        })
        .catch(err => {
          // An error occurred
        })
      },
      onSwitchShow(e){
        this.switchCategoryState(e, {column: 'is_show'});
      },
      onSwitchInHome(e){
        this.switchCategoryState(e, {column: 'in_home'});
      },
      searchCategory(){
        let title = this.title;
        this.getCategories({page: 1, title});
      }
    }
  }
</script>

<style>
  .kt-widget__head .kt-widget__info {
    overflow: hidden;
  }

  .kt-widget__section {
    overflow: hidden;
    height: auto;
  }

  .kt-widget__data {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .kt-widget__username {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: justify;
    display: block;
    width: 100%;
  }
</style>
